.blog-card,.blog-card:hover,.blog-card:focus{
    box-shadow: 0 0 10px rgb(0 5 39 / 10%);
    padding: 0;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;
}
.blog-card:last-child,.blog-card:hover:last-child,.blog-card:focus:last-child,.blog-card:nth-last-child(2),.blog-card:hover:nth-last-child(2),.blog-card:focus:nth-last-child(2),.blog-card:nth-last-child(3),.blog-card:hover:nth-last-child(3),.blog-card:focus:nth-last-child(3){
    margin-bottom: 0;
}
.blog-card .blog-img{
    width: 100%;
    height: 15vw;
    vertical-align: top;
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 10px;
}
.blog-card .blog-body{
    padding: 10px;
}
.blog-card:hover{
    box-shadow: 0 0 10px rgb(0 5 39 / 30%);
}
.blog-card:hover .blog-img{
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.blog-card .blog-title{
    color: var(--navy);
    font-size: 20px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.blog-card .blog-content{
    font-size: 14px;
    margin-bottom:0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
@media(max-width:1199px){
    .blog-card:nth-last-child(3),.blog-card:hover:nth-last-child(3),.blog-card:focus:nth-last-child(3){
        margin-bottom: 20px;
    }
    .blog-card .blog-img{
        height: 21vw;
    }
}
@media(max-width:991px){
    .blog-card:last-child,.blog-card:hover:last-child,.blog-card:focus:last-child,.blog-card:nth-last-child(2),.blog-card:hover:nth-last-child(2),.blog-card:focus:nth-last-child(2){
        margin-bottom: 20px;
    }
}
@media(max-width:767px){
    .blog-card .blog-title{
        font-size: 20px;
    }
}
@media(max-width:575px){
    .blog-card .blog-title{
        font-size: 18px;
    }
    .blog-card .blog-content{
        font-size: 13px;
    }
    .blog-card .blog-img{
        height: 42vw;
    }
}